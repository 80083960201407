.block_ {
    display: block;
    font-weight: bold;
    margin-bottom: 6pt;
    margin-left: 0;
    margin-right: 0;
    margin-top: 12pt;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: center
    }
.block_1 {
    display: block;
    margin-bottom: 6pt;
    margin-left: 0;
    margin-right: 0;
    margin-top: 12pt;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: justify
    }
.block_2 {
    display: block;
    margin-bottom: 6pt;
    margin-left: 0;
    margin-right: 0;
    margin-top: 6pt;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: justify
    }
.block_3 {
    display: block;
    font-weight: bold;
    margin-bottom: 6pt;
    margin-left: 0;
    margin-right: 0;
    margin-top: 12pt;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: center;
    text-transform: uppercase
    }
.block_4 {
    display: table-row;
    margin-bottom: 6pt;
    margin-left: 0;
    margin-right: 0;
    margin-top: 6pt;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: justify
    }
.block_5 {
    background-color: #FFF;
    display: block;
    font-weight: bold;
    margin-bottom: 6pt;
    margin-left: 0;
    margin-right: 0;
    margin-top: 12pt;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: center
    }
.block_6 {
    display: table-row;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 6pt;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: justify
    }
.block_7 {
    display: table-row;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: justify
    }
.block_8 {
    display: table-row;
    margin-bottom: 6pt;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: justify
    }
.block_9 {
    display: block;
    font-weight: bold;
    margin-bottom: 6pt;
    margin-left: 0;
    margin-right: 0;
    margin-top: 12pt;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    page-break-after: avoid;
    text-align: center
    }
.block_10 {
    display: block;
    font-weight: bold;
    margin-bottom: 6pt;
    margin-left: 0;
    margin-right: 0;
    margin-top: 12pt;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    page-break-after: avoid;
    page-break-inside: avoid;
    text-align: center;
    text-transform: uppercase
    }
.block_11 {
    display: block;
    margin-bottom: 6pt;
    margin-left: 64.8pt;
    margin-right: 0;
    margin-top: 6pt;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: justify
    }
.block_12 {
    display: block;
    font-weight: bold;
    margin-bottom: 6pt;
    margin-left: 0;
    margin-right: 0;
    margin-top: 12pt;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: justify
    }
.block_13 {
    display: block;
    font-family: serif;
    font-size: 0.75em;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 12pt;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
    text-align: justify
    }
.block_14 {
    display: block;
    font-family: serif;
    font-size: 0.75em;
    margin-bottom: 6pt;
    margin-left: 0;
    margin-right: 0;
    margin-top: 6pt;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0
    }
.bullet_ {
    display: table-cell;
    font-weight: bold
    }
.calibre {
    color: black;
    display: block;
    font-family: "Times New Roman", serif;
    font-size: 1em;
    margin-bottom: 0;
    margin-left: 5pt;
    margin-right: 5pt;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0
    }
.calibre1 {
    font-weight: bold
    }
.calibre2 {
    display: table;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 36pt;
    padding-right: 0;
    padding-top: 0
    }
.calibre3 {
    display: table-cell
    }
.calibre4 {
    display: table;
    margin-bottom: 0;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
    padding-bottom: 0;
    padding-left: 72pt;
    padding-right: 0;
    padding-top: 0
    }
.calibre5 {
    white-space: pre-wrap
    }
.calibre6 {
    font-style: italic
    }
.text_ {
    color: #0563C1;
    text-decoration: underline
    }
.text_1 {
    font-style: italic;
    font-weight: bold
    }
.text_2 {
    color: black
    }
.text_3 {
    color: black;
    font-weight: bold
    }
.text_4 {
    color: #000
    }

@page {
    margin-bottom: 5pt;
    margin-top: 5pt
    }

